<template>
  <v-app>
    <v-card outlined class="my-3 pa-3">
      <small>Sitting ID</small>
      <input :disabled="busy || sittingDetails" type="text" placeholder="Enter Sitting ID" v-model="sittingId" />
      <div class="mt-2 mb-6">
        <button :disabled="busy || !sittingId.length || sittingDetails" class="es-btn teal mr-2" @click="loadSittingDetails">
          <small>Load sitting details</small>
        </button>
        <v-progress-circular v-if="busy && !sittingDetails" indeterminate size="28" color="amber"></v-progress-circular>
      </div>

      <v-card v-if="sittingDetails" class="pa-4 mb-4">
        <section name="details" class="d-flex flex-wrap">
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Product Id</small><br />{{ sittingDetails.product_id }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">User Id</small><br />{{ sittingDetails.user_id }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Sitting</small><br />{{ sittingDetails.status }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Grading</small><br />{{ sittingDetails.grading_status }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Assessment</small><br />{{ sittingDetails.assessment_type_name }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Skill</small><br />{{ sittingDetails.assessment_skills.join(', ') }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">CEFR</small><br />{{ sittingDetails.score.cefr }}</div>
          <div class="mr-8"><small class="blue-grey--text text--lighten-1">Score</small><br />{{ sittingDetails.score.englishscore }}</div>
          <div class="mr-8" v-for="breakdown in Object.keys(sittingDetails.score.breakdown)" :key="breakdown">
            <small class="blue-grey--text text--lighten-1 text-capitalize">{{ breakdown }}</small><br /><span class="text-capitalize">{{ sittingDetails.score.breakdown[breakdown] }}</span>
          </div>
          <div class="ml-auto mr-2">
            <small class="blue-grey--text text--lighten-1"><!--Proctoring--></small><br />
              <v-btn title="Proctor sittings for this user" dark color="teal" small :to="{ path: '/proctoring', query: { search: sittingDetails.user_id } }">
              <v-icon small>mdi-image-multiple</v-icon>
            </v-btn>
          </div>
          <div class="mr-2">
            <small class="blue-grey--text text--lighten-1"><!--Events--></small><br />
            <v-btn title="View the User Events for this sitting" dark color="accent" small :to="{ path: '/user-events', query: { uid: sittingDetails.user_id, ed: eventDate(sittingDetails.started_time) } }">
              <v-icon small>mdi-account-group</v-icon>
            </v-btn>
          </div>
          <div>
            <small class="blue-grey--text text--lighten-1"><!--Assessment--></small><br />
            <v-btn title="View the Assessment Instance for this sitting" dark color="indigo" small :to="{ path: `/assessment-instances/${sittingDetails.assessment_instance_id}/items` }">
              <v-icon small>mdi-view-list-outline</v-icon>
            </v-btn>
          </div>
        </section>

        <section name="actions" class="pt-6">
          <button :disabled="busy || !sittingId.length || certificateOrderPlaced" class="es-btn primary mr-2" @click="placeCertificateOrder">
            <small>Place a Certificate Order for this Sitting</small>
          </button>
          <button :disabled="busy || !sittingId.length" class="es-btn mr-2" @click="reset">
            <small>Reset</small>
          </button>
          <v-progress-circular v-if="busy" indeterminate size="28" color="amber"></v-progress-circular>
        </section>
      </v-card>

    </v-card>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon';

export default {
  computed: {
    ...mapState('certify', [
      'busy',
      'sitting_id',
      'sittingDetails',
      'certificateOrderPlaced',
    ]),
    sittingId: {
      get() {
        return this.sitting_id || ''
      },
      set(value) {
        this.$store.commit('certify/setSittingId', value)
      }
    }
  },
  methods: {
    eventDate(date) {
      return DateTime.fromISO(date).toFormat('yyyy,MM,dd')
    },
    reset() {
      this.sittingId = ''
      this.$store.commit('certify/setSittingDetails', false)
      this.$store.commit('certify/setCertificateOrderPlaced', false)
    },
    loadSittingDetails() {
      this.$store.dispatch('certify/loadSittingDetails')
    },
    placeCertificateOrder() {
      this.$store.dispatch('certify/placeCertificateOrder')
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reset()
        this.sittingId = this.$route.query.sittingId
      },
    },
    sittingId() {
      if (this.sittingId.length) {
        this.$router.replace({ query: { sittingId: this.sittingId } })
      } else {
        this.$router.replace({ query: {} })
      }
    }
  },
  mounted() {
    this.$store.commit('certify/setSittingDetails', false)
  },
}
</script>


<style lang='scss' scoped>
input,
select {
  @include input;
}
.no-break {
  white-space: nowrap;
}
</style>
